/*
FormAssist Component
*/

import React, { Component} from "react";
import {Button} from "reactstrap";
import FormsTable from "../FormsTable/FormsTable";
import {globals} from "../../Globals/Globals"

class FormAssist extends Component {
    constructor(props){
        super(props)

        this.mode = {Browse:0, FormsConfig:1, EditTable:2};
        this.state={
                    form_name:"Todo Templates", 
                    fields:[], 
                    fields_are_valid:true, 
                    form_data:[],
                    user_mode:this.mode.Browse,
                    debug:(globals.parameters.debugging == "true")}

        //micro-services url
        this.rbase_service_url = (["localhost", "127.0.0.1"].includes(window.location.hostname))
                                ?'http://localhost:4000'
                                :'https://rbase-service.neuronal.bible';
    }

    componentDidMount = () => {
        //fetch available forms
        this.fetchForms();
    }

    /**
     * 
     * @returns Purpose : Load available forms from database
     */
     fetchForms = async () => {
        //Call micro-service
        var serviceResult = null
        try{
            await fetch(
                    `${this.rbase_service_url}/forms-list`,{
                        headers: {
                                    'Content-Type': 'application/json'},
                        method: "GET",}).then(
                results => {return results.text();}
                ).then(
                  (server_response) =>{
                   
                    serviceResult = JSON.parse(server_response);
                    if (globals.parameters.debugging){
                        console.log("server_response =>", server_response)
                        console.log("client_add_requetes_log => returns : ", serviceResult);
                    }

                    //------------------------------------------ Asynchronous promise result handle
                    //Get service aknowledge before resetting state values.

                    if (serviceResult.state === true) {
                        //Form sucessfully loaded
                        this.setState({availableForms:serviceResult.items})
                    }
                    else{
                        alert("Erreur:" + serviceResult.message);
                    }
                    //--------------------------------------- End of promise Accept
                  } 
                )
        } catch(err){
            alert(`${globals.dico.fr.message.verifier_connexion_wifi} : ${err}`);
            return;
        }    
     }

    /**
     * Purpose : Create a new Form
     */
    onNewFormClick = (event) => {
        event.preventDefault();
        this.editBlankFormStructure()
    }

    /**
     * Purpose : Open an existing form fields grid for configuration
     */
    onEditFormClick = event => {
        event.preventDefault();
        let selectedFormId = parseInt(event.target.getAttribute("idforms"))
        let selectedFormName = event.target.getAttribute("form_name")

        //set selected form
        this.setState({idforms:selectedFormId, form_name:selectedFormName}, () => {
            //load form fields from form-field table
            this.loadFormStructure(
                (serviceResult) => {
                    //On Success
                    this.setState({fields:serviceResult.items}, ()=>{console.log("state:", this.state);})
                    
                    //Change mode only when success
                    this.setState({user_mode:this.mode.FormsConfig}, ()=>{alert(`${globals.dico.fr.message.formulaire_charge}`);})  
                },
                (serviceResult) => {
                    //On Failed
                    alert("Erreur:" + serviceResult.message);
                }
            )
        }) 
    }

    /**
     * Purpose : Run a form in order to populate fields with data 
     */
    onRunFormClick = event => {
        event.preventDefault();
        let selectedFormId = parseInt(event.target.getAttribute("idforms"))
        let selectedFormName = event.target.getAttribute("form_name")

        this.userInputFormData(selectedFormId, selectedFormName);
    }

    userInputFormData = (p_intSelectedIdForm, p_stringSelectedFormName) => {
        //set selected form
        this.setState({idforms:p_intSelectedIdForm, form_name:p_stringSelectedFormName}, () => {
            //load form fields from form-field table
            this.loadFormData()
        })
    }

    /**
     * Purpose : Save a new form configuration / settings 
     */
    onSaveFormStructureClick = event => {
        event.preventDefault();
        console.clear();
        
        console.log(`onSaveFormStructureClick with idforms`);

        this.saveFormStructure();

        //force reload fetch and return to list in order to force
        this.setState({user_mode:this.mode.Browse}, () => {this.fetchForms();});
    }

    /**
     * Purpose : Update an existing form configuration / settings 
     */
    onUpdateFormStructureClick = event => {
        event.preventDefault();
        
        let selectedFormId = this.state.idforms
        console.log(`onUpdateFormStructureClick with idforms :${selectedFormId}`);

        this.updateFormStructure();
    }

    /**
     * purpose Update current Form in "form" table plus fields structure into "forms_fields" Table
     */
    updateFormStructure = async () => {
        //Call micro-service
        var serviceResult = null
        try{
            await fetch(
                    `${this.rbase_service_url}/form-update`,{
                        headers: {
                                    'Content-Type': 'application/json'},
                        method: "POST", 
                        body: JSON.stringify(this.state)}).then(
                results => {return results.text();}
                ).then(
                  (server_response) =>{
                    console.log("form-add server response =>", server_response)
                    serviceResult = JSON.parse(server_response);
                    //------------------------------------------ Asynchronous promise result handle
                    //Get service aknowledge before resetting state values.
                    console.log("form-add => returns : ", serviceResult);

                    if (serviceResult.state === true) {
                        //
                        alert(`${globals.dico.fr.message.formulaire_cree}`)
                    }
                    else{
                        alert("Erreur:" + serviceResult.message);
                    }
                    //--------------------------------------- End of promise Accept
                  } 
                )
        } catch(err){
            alert(`${globals.dico.fr.message.verifier_connexion_wifi} : ${err}`);
            return;
        }
    }  

    /**
     * purpose Save current Form in "form" table plus fields structure into "forms_fields" Table
     */
    saveFormStructure = async event => {
        console.clear();
        console.log("/form-add :: this.state:", this.state)
        //Call micro-service
        var serviceResult = null
        try{
            await fetch(
                    `${this.rbase_service_url}/form-add`,{
                        headers: {
                                    'Content-Type': 'application/json'},
                        method: "POST", 
                        body: JSON.stringify(this.state)}).then(
                results => {return results.text();}
                ).then(
                  (server_response) =>{
                    console.log("form-add server response =>", server_response)
                    serviceResult = JSON.parse(server_response);
                    //------------------------------------------ Asynchronous promise result handle
                    //Get service aknowledge before resetting state values.
                    console.log("form-add => returns : ", serviceResult);

                    if (serviceResult.state === true) {
                        //
                        alert(`${globals.dico.fr.message.formulaire_cree}`)
                    }
                    else{
                        alert("Erreur:" + serviceResult.message);
                    }
                    //--------------------------------------- End of promise Accept
                  } 
                )
        } catch(err){
            alert(`${globals.dico.fr.message.verifier_connexion_wifi} : ${err}`);
            return;
        }
    }  

    onReturnToFormsListClick = async event => {
        this.setState({user_mode:this.mode.Browse})
    }

    editBlankFormStructure = async event => {
         //Promt a new form name
         let nextFormName = window.prompt("Nom du formulaire","Sans nom");
         if ((nextFormName == null) || (nextFormName.trim().length < 3))
             return
 
         //Set state with new name
         this.setState({form_name:nextFormName, idforms:-1, form_data:[], fields:[]}, ()=>{
                 //Load or Create blank structure
                 this.setState({user_mode:this.mode.FormsConfig})
             })
    }


    loadFormData = async event => {
        //Call micro-service
        let service_db_url = `${this.rbase_service_url}/form-load-data?idforms=${this.state.idforms}`;

        var serviceResult = null
        try{
            await fetch(
                    `${service_db_url}`,{
                        headers: {
                                    'Content-Type': 'application/json'},
                        method: "GET",}).then(
                results => {return results.text();}
                ).then(
                  (server_response) =>{
                    serviceResult = JSON.parse(server_response);
                    
                    if (globals.parameters.debugging){
                        console.log("server_response =>", server_response)
                        console.log("form-load-data => returns : ", serviceResult);
                    }
                    
                    //------------------------------------------ Asynchronous promise result handle
                    //Get service aknowledge before resetting state values.

                    if (serviceResult.state === true) {
                        //Form sucessfully loaded
                        this.setState({form_data:serviceResult.items})
                        
                        this.setState({user_mode:this.mode.EditTable})
                        alert(`${globals.dico.fr.message.formulaire_charge}`)
                    }
                    else{
                        alert("Erreur:" + serviceResult.message);
                    }
                    //--------------------------------------- End of promise Accept
                  } 
                )
        } catch(err){
            alert(`${globals.dico.fr.message.verifier_connexion_wifi} : ${err}`);
            return;
        }
    }  

    /**
     * Purpose : Load fields structure from database
     * @param {*} onSuccess 
     * @param {*} onFailed 
     * @returns 
     */
    loadFormStructure = async (onSuccess, onFailed) => {
        console.log(`loadFormStructure formid:${this.state.idforms}`)
        //Call micro-service
        let service_db_url = `${this.rbase_service_url}/form-load-structure?idforms=${this.state.idforms}`;

        var serviceResult = null
        try{
            await fetch(
                    `${service_db_url}`,{
                        headers: {
                                    'Content-Type': 'application/json'},
                        method: "GET",}).then(
                results => {return results.text();}
                ).then(
                  (server_response) =>{
                    console.log("server_response =>", server_response)
                    serviceResult = JSON.parse(server_response);

                    //------------------------------------------ Asynchronous promise result handle
                    //Get service aknowledge before resetting state values.
                    if (serviceResult.state === true) {
                        //Form sucessfully loaded
                        if (onSuccess) onSuccess(serviceResult); else alert("loadFormStructure success");
            
                    }
                    else{
                        if (onFailed()) onFailed(serviceResult); else alert("loadFormStructure failed");
                       
                    }
                    //--------------------------------------- End of promise Accept
                  } 
                )
        } catch(err){
            alert(`${globals.dico.fr.message.verifier_connexion_wifi} : ${err}`);
            return;
        }
    }  

    handle_field_change = changeBundle => {
        this.setState({fields: changeBundle.nextFields, fields_are_valid:changeBundle.fields_are_valid})

        /*
        console.log("changeBundle =>", changeBundle)
        console.log("nexFields:", fields, "fields_are_valid:", fields_are_valid)*/
    }

    handle_data_change = changeDataBundle => {
        //Update form_data memory implementation
        let next_form_data = this.state.form_data
        next_form_data[changeDataBundle.recordid][changeDataBundle.fieldname] = 
            changeDataBundle.value;
        
        this.setState({form_data : next_form_data}, ()=>{console.log("handle_data_change proceed")})
    }


    /**
     * 
     * Purpose : add an empty Row entry from memory
     */
     addBlankTableRecord  = () => {
        console.clear();
        console.log("> addBlankTableRecord");

        //1-load form fields from form-field table
        this.loadFormStructure(
            (serviceResult) => {
                //On Success
                console.log("fields loaded from addBlankTableRecord:", serviceResult.items);
                //Add record
                const blankRecordStringified =
                serviceResult.items.map((fieldItem) => {
                    return (
                        `"${fieldItem.field_name}":null`
                    )
                }).join(",");
    
                //
                console.log("blankRecordStringified:",`{"auto_rbase_id":0,${blankRecordStringified}}`)
                
                //Duplicate first record
                //let blank_record = JSON.parse(JSON.stringify(this.state.form_data[0]));///Deep copy instead of shallow one
                let blank_record = JSON.parse(`{"auto_rbase_id":0,${blankRecordStringified}}`);///Deep copy instead of shallow one
        
                
                console.log("blank_record", blank_record);
                //then blank it
                for (const key in blank_record) {
                    blank_record[key] = null;
                }
        
                //Store new form data property
                this.setState({form_data:[...this.state.form_data, blank_record]}, () => {
                    console.log("addBlankTableRecord :: this.state:",this.state)
                })
                
                //Add record finished

                alert(`${globals.dico.fr.message.nouveau_record_ajoute}`);
            },
            (serviceResult) => {
                //On Failed
                alert("Erreur:" + serviceResult.message);
            }
        );

    }

    deleteCurrentRecord = (recordid) => {

        if (!window.confirm("Voulez-vous supprimer cette entrée")) return;

        console.clear();
        console.log("-------- deleteCurrentRecord recordid =>", recordid)

        //Update form_data memory implementation
        let next_form_data = this.state.form_data
        next_form_data[recordid]["auto_rbase_id"] = -1

        //Store delete property
        this.setState({form_data : next_form_data}, ()=>{console.log("form_data After virtual delete => ",this.state.form_data)})
    }

    debuggingBrowseData = () => {
        console.log(">> debuggingBrowseData")
        //---debugging Insert into table statement
        //Object.keys(this.props.data).map((key, i) => {
        //Browse Data records
        Object.keys(this.state.form_data).map((key, i) => {
            console.log("key ===> ", key)
            console.log("this.props.data[key]", this.state.form_data[key])
            return(
                //Browse fields nested in record
                //Object.entries(this.props.records_data).map(
                Object.entries(this.state.form_data[key]).map(
                    (obj, ii) => 
                        {
                        console.log("nested Object.entries:", obj)
                        return(
                            console.log("obj =>", obj)
                            /*
                            <AutoEditField
                                ref={React.createRef()}
                                fieldname={obj[0]}
                                value={obj[1]}
                                placeholder={obj[0]}
                            />*/
                        )
                        }
                    )
            )
          })
    }

    /**
     * Purpose : map form_data array into SQL values clause for SQL Insert statement
     */
    createInsertStatements = () => {
        let clausesArray =
            Object.keys(this.state.form_data).map((key, auto_id) => {
                if (this.state.debug){
                    console.log("key ===> ", key)
                    console.log("this.props.data[key]", this.state.form_data[key])    
                    console.log("this.state.form_data[key].auto_rbase_id", this.state.form_data[key].auto_rbase_id)    
                }
                //Don't care virtually deleted records with negative keys
                if (this.state.form_data[key].auto_rbase_id != -1){
                    //Browse fields nested in record
                        let values_clause = "";
                        Object.entries(this.state.form_data[key]).forEach(fieldItem => {
                            if (this.state.debug){
                                console.log("fieldItem[0]===>", fieldItem[0])
                                console.log("fieldItem[0].indexOf(auto)===>", fieldItem[0].indexOf("auto"))
                            }

                            if (fieldItem[0].indexOf("auto_rbase_id") == 0)
                                values_clause += `${1 + auto_id}` //+1 offset because MySql does not accept zero auto_increment index     
                            else{
                                let field_value = (fieldItem && fieldItem[1])?fieldItem[1].replace(/'/g, "''").replace(/[\n\r]/g, ' ').replace(/"/g, '\\"'):fieldItem[1]
                                values_clause += `,'${field_value}'`;
                            }
                                
                    }); 
                    return(values_clause) //return computed values clause
                }
                else return("");//Empty insert clause
            })

        if (this.state.debug)
            console.log("clausesArray:", clausesArray)

        return clausesArray;
    }

    /**
     * Purpose save all records from state.record_data array
     */
     saveAllRecords = async () => {
        if (globals.parameters.debugging){
            console.clear();
            console.log("saveAllRecords")
            console.log(this.state.form_data);
        }

        //Build Insert Clause Object
        let insertClauseStatementObject = {
            idforms : this.state.idforms,
            insertClauses : this.createInsertStatements()
        }
        
        //Then, call /form-save-data micro-service end point
        var serviceResult = null
        try{
            await fetch(
                    `${this.rbase_service_url}/form-save-data`,{
                        headers: {
                                    'Content-Type': 'application/json'},
                        method: "POST", 
                        body: JSON.stringify(insertClauseStatementObject)}).then(
                results => {return results.text();}
                ).then(
                  (server_response) =>{
                    
                    serviceResult = JSON.parse(server_response);

                    //------------------------------------------ Asynchronous promise result handle
                    if (globals.parameters.debugging){
                        console.log("form-save server response =>", server_response)
                        //Get service aknowledge before resetting state values.
                        console.log("form-save => returns : ", serviceResult);
                    }

                    if (serviceResult.state === true) {
                        //
                        alert(`${globals.dico.fr.message.donnees_formulaire_enregistrees}`)
                    }
                    else{
                        alert("Erreur:" + serviceResult.message);
                    }
                    //--------------------------------------- End of promise Accept
                  } 
                )
        } catch(err){
            alert(`${globals.dico.fr.message.verifier_connexion_wifi} : ${err}`);
            return;
        }
    }

    render = () =>{
    return(
        <>
            <h1>{globals.dico.fr.interface.main_title}</h1>

            <div className="container">
                {((this.state.user_mode == this.mode.Browse) 
                    && (this.state.availableForms)) &&
                <div>
                    <h4>{globals.dico.fr.interface.browse_page_sub_title}</h4>
                    {
                        <ol>
                        {
                        this.state.availableForms.map(
                            formItem => {
                                return(
                                    <li>
                                        <div className="row">
                                            <Button
                                                disabled={globals.parameters.read_forms_only} 
                                                form_name={formItem.form_name}
                                                idforms={`${formItem.idforms}`} 
                                                UUID={`${formItem.table_UUID}`} 
                                                onClick={this.onEditFormClick}>Configurer</Button>
                                            &nbsp;
                                            <Button 
                                                form_name={formItem.form_name}
                                                idforms={`${formItem.idforms}`}
                                                onClick={this.onRunFormClick}>Saisir</Button>
                                            
                                            <h4>&nbsp;{formItem.form_name}</h4>
                                        </div>
                                    </li>
                                )
                            }
                        )
                        }
                        </ol>
                    }
                    <div className="row">
                        <Button 
                            disabled = {globals.parameters.read_forms_only}
                            onClick={this.onNewFormClick}><strong>+</strong>{globals.dico.fr.button.nouveau_formulaire}</Button>
                    </div>
                </div>}

                {(this.state.user_mode == this.mode.FormsConfig) &&
                <div style={{backgroundColor:"lightblue"}}>
                    <h4>Configure selected form</h4>

                    <FormsTable 
                        form_name={this.state.form_name}
                        onFieldChange={this.handle_field_change}
                        fields={this.state.fields}
                        validFlag={this.state.fields_are_valid}
                        data={this.state.form_data}
                        edit_mode={true}/>

                    <div className="row">
                        {this.state.idforms == -1 && <>
                            <Button onClick={this.onSaveFormStructureClick}>Enregistrer la nouvelle configuration et fermer</Button></>}
                        {this.state.idforms > 0 && <>
                            <Button onClick={this.onUpdateFormStructureClick}>Enregistrer les modifications</Button></>}
                        <Button onClick={this.onReturnToFormsListClick}>{globals.dico.fr.button.retour_a_la_liste}</Button>
                    </div>
                </div>}

                {(this.state.user_mode == this.mode.EditTable) &&
                <div>
                    <h4>{globals.dico.fr.interface.input_data_page_sub_title}</h4>

                    <FormsTable
                        form_name={this.state.form_name} 
                        onAddRecordHandler = {() => {this.addBlankTableRecord()}}
                        onDeleteRecordHanlder = {(recordid) => {this.deleteCurrentRecord(recordid)}}
                        onSaveAllRecordsHandler = {() => {this.saveAllRecords()}}
                        onDataChange={(changeDataObject) => {
                                    this.handle_data_change(changeDataObject)
                            }
                        }
                        onFieldChange={this.handle_field_change}
                        fields={this.state.fields}
                        validFlag={this.state.fields_are_valid}
                        data={this.state.form_data}
                        edit_mode={false}/>

                    <div className="row">
                        <Button onClick={this.onReturnToFormsListClick}>{globals.dico.fr.button.retour_a_la_liste}</Button>
                    </div>
                </div>}
            </div>
        </>
    )}
}

export default FormAssist;