import React, {Component } from 'react'
import { Button } from 'reactstrap'
import FieldsTypesCombo from '../FieldsTypesCombo/FieldsTypesCombo'
import RecordsTable from '../RecordsTable/RecordsTable'
import {globals} from "../../Globals/Globals"
import './FormsTable.css'

class FormsTable extends Component{

    constructor(props){
        super(props)

        //Titles
        this.title_liste_des_champs_du_formulaire = "Champs du formulaire"

        //Message

        //Prompt
        this.prompt_no_du_champ = "Nom du champ"
        //Confirm
        this.confirm_supprimer_un_champ = "Supprimer ce champ ?"
        

        //err
        this.err_nom_de_champ_trop_long = "Nom de champ trop long\r\n"
        this.err_nom_de_champ_trop_court = "Nom de champ trop court\r\n"
        this.err_type_de_champ_non_selectionne = "Type de champ non sélectionné\r\n"
    }

    componentDidMount = () => {
        this.onDescriptionChange = this.onDescriptionChange.bind(this)
    }

    componentDidUpdate = () => {
      //console.log("this.props.data =>", this.props.data)
    }

    fields_row_rendering = (fieldItem, itemid) =>{
      console.log("fields_row_rendering => fieldItem:", fieldItem)
      return(
        <tr>
          <td>{1 + itemid}</td>
          <td><Button onClick={()=>{this.deleteField(itemid)}}>{globals.dico.fr.button.effacer_un_champ}</Button></td>
          <td><FieldsTypesCombo selected_fields_types_object={this.props.fields[itemid].fields_typesObjectItem} item_id={itemid} onClick={this.onFieldTypeSelected}/></td>
          <td><input item_id={itemid} type="text" value={fieldItem.field_title} onChange={this.onDescriptionChange} prompt={this.prompt_no_du_champ}/></td>
          <td><label>{fieldItem.error}</label></td>
        </tr>
      )
    }

    fields_rendering = () => {
      const table_body =
        this.props.fields.map((fieldItem,ii) => {
          return this.fields_row_rendering(fieldItem,ii)
        })

      return table_body
    }

    deleteField = (itemid) => {
      if (window.confirm(this.confirm_supprimer_un_champ)){
        let next_fields = this.props.fields;
        //delete next_fields[itemid];
        next_fields = next_fields.slice(0, itemid).concat(next_fields.slice(itemid+1, next_fields.length))

        this.updateValidationState(next_fields)
      }
    }

    addNewField = () => {
      console.log("this.props.fields:",this.props.fields)
      let next_fields=[...this.props.fields, {field_title:"", fields_typesObjectItem:null}]
      this.updateValidationState(next_fields)
    }

    is_field_valid = (p_fieldItem) => {
      const cond_1 = (p_fieldItem.field_title.trim().length > 45)?`${this.err_nom_de_champ_trop_long}, +${p_fieldItem.field_title.trim().length-45} caractère(s)\r\n`:""
      const cond_2 = (p_fieldItem.field_title.trim().length < 3)?`${this.err_nom_de_champ_trop_court}`:""
      const cond_3 =  (p_fieldItem.fields_typesObjectItem == null)?`${this.err_type_de_champ_non_selectionne}`:""

      const error_message = `${cond_1}${cond_2}${cond_3}`
      p_fieldItem.error = error_message
      return (error_message)
    }

    updateValidationState = (p_next_fields) => {
      let next_fields = p_next_fields
      let l_bool_fields_are_valid = true

      next_fields.map((fieldItem) => {
        //Compute validation error message
        let error_message = this.is_field_valid(fieldItem)

        //Update global validation flag
        l_bool_fields_are_valid &= (error_message === "")
      })

      //Write and broadcast state to caller
      this.props.onFieldChange({nextFields:next_fields, fields_are_valid:l_bool_fields_are_valid}); /*Broadcast change*/
    }


    onFieldTypeSelected = (fields_typesObjectItem, item_id) =>{
      let next_fields = this.props.fields
      next_fields[item_id].fields_typesObjectItem = fields_typesObjectItem
      this.updateValidationState(next_fields)
    }

    onDescriptionChange = e => {
      let item_id = parseInt(e.target.getAttribute("item_id"),10);
      let next_fields = this.props.fields;
      next_fields[item_id].field_title = e.target.value;
      console.log("item_id:", item_id, " e.target.value:", e.target.value)
      this.updateValidationState(next_fields);
    }

    /**
     * EDIT rendering */
    editRendering = () => {
      return(
        <div id="fields-table-block" className="col-12">
            <div className="bloc-title">{this.title_liste_des_champs_du_formulaire}</div>
            {(this.props.fields && this.props.fields.length > 0 && !this.props.validFlag) && <p>{globals.dico.fr.message.veuillez_saisir_les_champs}</p>}
           
            <Button color="primary" onClick={()=>{this.addNewField()}}>{globals.dico.fr.button.ajouter_un_champ}</Button>
             
            {(this.props.fields != null) &&
            <table className="edit-table">
              {this.fields_rendering()}        
            </table>}

            
        </div>
      )
    }

    /**
     * RUN rendering */
    runRendering = () => {
      return(
        <>
        {/* Next Run rendering code */}
            <div style={{textAlign:"left"}}>
              <h5><b>{globals.dico.fr.interface.label_formulaire}</b>{this.props.form_name}</h5>
              <h6><b>{globals.dico.fr.interface.label_nombre_lignes_formulaire}</b>{this.props.data.length}</h6>
            </div>
            <table>
              <tr>
                <td colspan="1000" align="left">
                  <Button onClick={() => {if (this.props.onSaveAllRecordsHandler) this.props.onSaveAllRecordsHandler()}}>{globals.dico.fr.button.tout_enregistrer}</Button>&nbsp;
                  <Button onClick={() => {if (this.props.onAddRecordHandler) this.props.onAddRecordHandler();}}><b>+</b>{globals.dico.fr.button.ajouter_un_enregistrement}</Button>&nbsp;
                  <Button disabled = {globals.parameters.read_forms_only}>{globals.dico.fr.button.exporter}</Button>&nbsp;
                  <Button disabled = {globals.parameters.read_forms_only}>{globals.dico.fr.button.partager}</Button>
                </td>
              </tr>
              {
                Object.keys(this.props.data).map((key, idrecord) => {

                  if (globals.parameters.debugging){
                    console.log("key ===> ", key, " idrecord ==>", idrecord)
                    console.log("this.props.data[key]", this.props.data[key])
                    console.log("this.props.data[key]['auto_rbase_id']", this.props.data[key].auto_rbase_id)
                    console.log("this.props.data[key].auto_rbase_id']", this.props.data[key]["auto_rbase_id"])
                  }

                  //Don't care virtually deleted records with negative keys
                  if (this.props.data[key].auto_rbase_id != -1)
                  return(
                    <RecordsTable 
                      idrecord={idrecord}
                      records_data={this.props.data[key]}
                      onAddRecordHandler = {()=>{if (this.props.onAddRecordHandler) this.props.onAddRecordHandler()}}
                      onDeleteRecordHanlder = {(recordid)=>{if (this.props.onDeleteRecordHanlder) this.props.onDeleteRecordHanlder(recordid)}}
                      onDataChange={(changeDataObject) => {
                              if (this.props.onDataChange){
                                  this.props.onDataChange(changeDataObject)
                              }                                            
                          }
                      }
                    />
                  )
                })
              }
            </table>
      </>
      )
    }

    render = () => {
      if (this.props.edit_mode){
        return (
        <>
          {globals.parameters.debugging &&
          <h1 className="edit">Edit Mode</h1>}
          {this.editRendering()}
        </>);
      }
      else return(
      <>
        {globals.parameters.debugging &&
        <h1 className="run">Run Mode</h1>}
        {this.runRendering()}
      </>)
        
    }
} 

export default FormsTable;
