/*
types Component
*/

import React, {Component, } from 'react';
import axios from 'axios';
import {DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle} from "reactstrap";
import './FieldsTypesCombo.css';

class FieldsTypesCombo extends Component{
    constructor(props){
        super(props);
        console.log("props => ", props);
        this.state = {types:[], selected_types_object:null, open:false, filter:""}

        this.msg_choisir_un_type_de_champ = "Choisir un type de champ ..."
    }

    componentDidMount = () => {
        this.fetchtypes();
    }

    fetchtypes = async () => {
        const localURL = 'http://localhost:4000/types-list'
        const remoteURL = 'https://rbase-service.neuronal.bible/types-list'
        const serviceURL = (["localhost", "127.0.0.1"].includes(window.location.hostname))?localURL:remoteURL
        const fetchresult = await axios.get(serviceURL);
        this.setState({types : fetchresult.data.items}, () => {this.setState({selected_types_object:this.props.selected_types_object});});
    };

    findtypesObjectWithId = (idtypes_searched) => {//Look for types object given a idtypes
        let ii = 0;
        console.clear();
        while (ii < this.state.types.length){
            console.log("ii:",ii," =>", this.state.types[ii]);
            if (this.state.types[ii].idtypes === idtypes_searched) return this.state.types[ii];
            ii++;
        }
        return null;
    }

    onFilterChange = (e) => {
        this.setState({filter:e.target.value})
    }

    typesObjectRendered = (typesObject) => {
        if (!typesObject)
            return(<span>{this.msg_choisir_un_type_de_champ}</span>)
        else
            return(
                    <span>&nbsp;{typesObject.type_name}</span>  
            );
    }
    
    typesDropDownRenderer = () => {
        const renderedDropDown = 
            this.state.types.filter((types) => types.type_name.toLowerCase().indexOf(this.state.filter.toLowerCase(),0) === 0)
            .map(
            typesObjectItem => {
                return (
                    <DropdownItem key={typesObjectItem.idtypes} 
                        onClick = {() => {
                                            this.setState({selected_types_object:typesObjectItem}); 
                                            if (this.props.onClick) this.props.onClick(typesObjectItem, this.props.item_id); 
                                            else alert("Callback is not yet defined");}}>
                        {this.typesObjectRendered(typesObjectItem)}
                    </DropdownItem>
                )
            }
        )

        return(
            <div>
                <ButtonDropdown isOpen={this.state.open} toggle={() => {this.setState({open:!this.state.open});}}>
                    <DropdownToggle caret>
                        {this.typesObjectRendered(this.props.selected_types_object)}
                    </DropdownToggle>
                        
                    <DropdownMenu>
                        {renderedDropDown}
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        )
    }

    render = () => {
        return(
            <div>{this.typesDropDownRenderer()}</div>
        )
    }
}

export default FieldsTypesCombo;