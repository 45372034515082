import './App.css';
import FormAssist from './Components/FormAssist/FormAssist';

function App() {
  return (
    <div className="App">
      <FormAssist/>
    </div>
  );
}

export default App;
