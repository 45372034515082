import React, {Component} from 'react'
import { Button } from "reactstrap"
import "./AutoEditField.css"

class AutoEditField extends Component{
    constructor(props){
        super(props)
        this.state = {
                    value:this.props.value,
                    has_changed: false
                }
    }

    componentDidMount = () =>{
        //this.clearForm()
        this.handle_change = this.handle_change.bind(this);
    }

    checkValidation = (fieldName, read_only = false) => {
        
        return true;

        let max = 45
        let min = 2
        
        let errorsFieldName = `err_${fieldName}`
        let nextState = this.state
        let nextErrors = this.state[errorsFieldName]
        nextErrors = []

         
        min = 2           
        max = 45
        
        if (this.state[fieldName].trim().length < min)
            nextErrors.push(`trop court.`)

        if (this.state[fieldName].length > max)
            nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)

        //update error field
        nextState[errorsFieldName] = nextErrors

        //set change flag
        nextState.has_changed = true

        //update internal state
        if (!read_only) //Avoid recursive setstate
            this.setState(nextState)

        //Return validation predicate
        return (nextErrors.length === 0) //returns true if no error occurs
    }

    handle_change = e => {
        e.preventDefault();

        this.setState({value : e.target.value, has_changed:true}, ()=>{this.checkValidation(e.target.name)})

        //Broadcast change to RecordsTable object
        if (this.props.onDataChange) {
            this.props.onDataChange(e.target.value)
        }
    }

    render = () => {
        return(
            <td>
                <input
                    className={`form-control ${this.state.has_changed ? 'modified' : 'normal'}`}
                    ref={this.props.ref}
                    name={this.props.name} 
                    value={this.state.value} 
                    onChange={this.handle_change} 
                    type={this.props.type} 
                    checked={this.props.checked}
                    placeholder={this.props.placeholder}
                    readOnly={this.props.read_only}
                />
            </td>
        )
    }
}

export default AutoEditField;