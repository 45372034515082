import React, {Component} from 'react'
import {Button} from 'reactstrap'
import AutoEditField from '../AutoEditField/AutoEditField'
import { globals } from '../../Globals/Globals'

class RecordsTable extends Component{
    constructor(props){
        super(props)
    }

    componentDidMount = () => {
         
    }

    
    render = () => {
        return(
            <tr key={this.props.idrecord}>
                <Button onClick={()=>{console.clear();console.log("RecordsTable props:", this.props);if (this.props.onDeleteRecordHanlder) this.props.onDeleteRecordHanlder(this.props.idrecord)}}><b>-</b>{globals.dico.fr.button.effacer_cet_enregistrement}</Button>
                &nbsp;
                <Button onClick={() => {if (this.props.onAddRecordHandler) this.props.onAddRecordHandler(); else alert("onAddRecordHandler is not defined !");}}><b>+</b>Ajouter</Button>
                {
                    Object.entries(this.props.records_data).map(
                    (obj, fieldid) => 
                        {
                        return(
                            <AutoEditField
                                ref={React.createRef()}
                                fieldname={obj[0]}
                                value={obj[1]}
                                placeholder={obj[0]}
                                onDataChange={(nextValue) => {
                                        if (this.props.onDataChange){
                                            //Create DataChange bundle parameter with recordid, fieldname, fieldid and value properties
                                            this.props.onDataChange(
                                                {
                                                    recordid:this.props.idrecord, 
                                                    fieldname:obj[0], 
                                                    fieldid:fieldid,
                                                    value:nextValue
                                                })
                                        }                                            
                                    }
                                }
                                read_only={(obj[0].indexOf("auto_rbase_id") == 0)?true:false} //Set auto_id field as read_only
                            />
                        )
                        }
                    )
                }
            </tr>
        )
    }
}

export default RecordsTable;